html,
body {
  padding: 0;
  margin: 0;
  background-color: #fff !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('https://fonts.gstatic.com/s/nunito/v11/XRXV3I6Li01BKofINeaBTMnFcQ.woff2')
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.combinators {
  display: none !important;
}

.ruleGroup-header {
  display: flex;
  gap: 12px;
}

.ruleGroup-addRule {
  padding-top: 6px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.rule {
  display: flex;
  margin: 10px 0;
  gap: 8px;
}

.rule-remove {
  padding-top: 6px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.queryBuilder-dragHandle {
  display: none !important;
}

.fields {
  width: 150px !important;
}

.operators {
  width: 150px !important;
}

.value {
  padding: 0 !important;
  width: 100px !important;
  align-items: end !important;
}

.MuiSelect-standard {
  min-width: 76px !important;
  height: 15px !important;
  font-size: 14px !important;
  min-height: unset !important;
}

.report-style-class {
  height: 69vh;
  margin: 1% auto;
  width: 60%;
}

.css-1i45gn3-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: 1px solid rgba(0 0 0 / 10%);
}

.MuiMenuItem-root {
  font-size: 14px !important;
}

.picker {
  position: relative;
}

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0 0 0 / 10%), inset 0 0 0 1px rgba(0 0 0 / 10%);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0 0 0 / 15%);
  background-color: #fff;
  z-index: 100;
}

.popover input {
  width: 100%;
}

textarea {
  border-width: 0;
}

textarea:focus {
  outline: none !important;
}

#column-menu {
  .MuiPaper-root {
    border: none;
    box-shadow: 0 4px 7px 0 rgba(0 0 0 / 10%);
    border-radius: 8px;
    background-color: #fff;
    padding: 32px;
    max-height: 500px;
  }
}

/* react-quill editor */
.ql-editor {
  min-height: 250px;
  overflow: auto;
}
